<template>
  <div>
    <Leaf
      class="banner-design-elements"
      width="100px"
      style="right: 200px; top:10px; opacity: 0.6"
      stroke
      :stroke-width="1"
    />
    <HalfCircleTR
      class="banner-design-elements"
      width="150px"
      style="right: 0px; top:0px; opacity: 0.4"
    />

    <HalfCircleBR
      class="banner-design-elements"
      width="200px"
      style="right: 50px; top:-20px; opacity: 0.6"
    />
  </div>
</template>

<script>
import Leaf from '@/components/design-elements/leaf.vue'
import HalfCircleBR from '@/components/design-elements/halfCircleBR.vue'
import HalfCircleTR from '@/components/design-elements/halfCircleTR.vue'

export default {
  components: {
    Leaf,
    HalfCircleBR,
    HalfCircleTR
  }
}
</script>
