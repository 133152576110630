<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 42.5 42.5"
    enable-background="new 0 0 42.5 42.5"
    xml:space="preserve"
  >

    <gradient-helper />

    <path
      stroke="url(#svg-gradient)"
      fill="url(#svg-gradient)"
      d="M42.5,42.5C42.5,19,23.5,0,0,0v42.5H42.5z"
    />
  </svg>
</template>

<script>
import svg from '@/mixins/svg'
import GradientHelper from './GradientHelper.vue'
export default {
  components: { GradientHelper },
  mixins: [svg]
}
</script>
