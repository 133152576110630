<template>
  <v-container class="layout-container">
    <BaseHeader
      title="Termine &amp; Fortbildung"
      :style-variant="7"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          Hier finden Sie einen Stundenplan mit unseren Fortbildungs- und Informationsangeboten.
          Melden Sie sich gleich an!
        </p>
      </template>
      <template v-slot:content>
        <v-breadcrumbs :items="breadcrumbItems" />
      </template>
    </BaseHeader>
    <v-col
      cols="12"
      class="pa-0"
    >
      <calendar />
    </v-col>
  </v-container>
</template>

<script>
import Calendar from '@/modules/calendar'
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from '@/modules/calendar/HeaderDesign.vue'

export default {
  components: {
    Calendar,
    BaseHeader,
    HeaderDesign
  },
  computed: {
    breadcrumbItems () {
      return [
        { text: 'Hilfe und Fortbildung', to: { path: '/support' }, disabled: false, exact: true },
        { text: 'Kalender' }
      ]
    }
  }
}
</script>
