<template>
  <v-card
    class="px-6"
    outlined
  >
    <v-card-text>
      <div
        class="mt-16"
      >
        <iframe
          :src="src"
          class="iframe"
          frameborder="0"
          allowfullscreen
        />
      </div>
      <p class="pt-3">
        Für Informationen zur Anmeldung öffnen Sie die Termine.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import featureNames from '@/lib/featureNames'

export default {
  data () {
    return {
      featureNames
    }
  },
  computed: {
    src () {
      return this.$features.feature(this.featureNames.EVENT_CALENDAR).config.targetUrl
    }
  }
}
</script>

<style scoped>
.iframe{
  width: 100%;
  height: 500px;
}
</style>
